@import url("./assets/css/styles.css");
@import url("./assets/css/slider.css");
@import url("./assets/css/socialmediaicon.css");
@import url("./assets/css/botonStyles.css");
@import url("./assets/css/tableColor.css");
@import url("./assets/css/widgetButon.css");
@import url("./assets/css/cardHover.css");

@tailwind base;
@tailwind components;
@tailwind utilities;



/*-----------------
    # Typography
-----------------*/
@import url("https://fonts.googleapis.com/css?family=Rubik:400,400i,500,600,700,800display=swap");
@import url("http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700");

:root {
  --main-color: #000000;
  --secondary-color: #82C800;
  --heading-color: #000000;
  --paragraph-color: #000000;
  --heading-font: "Rubik", sans-serif;
  --body-font: "Rubik", sans-serif;
  --line-height30: 1.7;
  --bg-footer: #000000;
  --btn: #00AFD7;
  --btn-hover: #00AFD7;
  --scroll-color: #FED50D;
  --icons-menu-movil: #ffffff;
  --three-color: #4ec1db;
  --bg-blocque: #e9e9e9;
  --overlay-video-content: rgba(0, 0, 0, 0.5);
}

/*contador de visita background*/
.kJVksy {
  background-color: var(--three-color);
}

/*step de appoiment*/
.bgStep {
  background-color: var(--btn);
}

.bgStep,
.lineaStep {
  border-color: var(--btn);

}

.numStep {
  color: var(--secondary-color);
}

/* fin step*/

.bgBtnMenu {
  background-color: var(--btn);
}

/* text color footer 3 */
.titleColorFt {
  color: var(--three-color);
}

/*icon de contact */
.bgIconContact {
  color: var(--secondary-color);
}

.bgIconContact :hover {
  color: var(--three-color);
}

/* bloque general */
.bgBloque {
  background-color: var(--secondary-color);
}

/*contador bloque cards*/
.bgColor1 {
  background-color: var(--secondary-color);
}

.bgColor2 {
  background-color: var(--three-color);
}

.bg1{
  background-color: var(--main-color);
}

.bg2{
  background-color: var(--secondary-color);
}

/**paleta de colores boton flotante*/
.bgbotonFlotante {
  background-color: var(--btn);
  border: 2px solid white;
  color: white;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
}

/*services detail*/
.bgBloqueServicesDetail {
  background-color: var(--secondary-color);

}

/* color del block 2*/
.bgContent-2 {
  background-color: var(--bg-blocque);
}

/* color del block 3 y image */
.bgContent-3 {
  background-color: var(--bg-blocque);
}

.bgContentImg-3 {
  background-color: var(--secondary-color);
}

/*bg top header*/

.bgHeaderTop {
  background-color: var(--secondary-color);

}


/* borde del bloque de la paleta de colores */
.borderColor {
  border-color: var(--secondary-color);
}

/*colores de los dots de values*/
.dotsColor {
  color: var(--secondary-color);
}

html {
  overflow-x: hidden;
  font-family: var(--body-font);
}

* {
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  color: var(--paragraph-color);
  font-family: var(--body-font);
  line-height: var(--line-height30);
}

body::-webkit-scrollbar {
  display: none;
}

.burguer-menu {
  background-color: var(--main-color);
  padding: 15px;
  color: white;
}

.btn-global {
  background: var(--secondary-color);
  color: white;
  border-radius: 30px;
}

.breadcrumb-area {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding: 380px 0 100px;

}

.breadcrumb-area::before {
  content: '';
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}


.bg-overlay {
  position: relative;
  background-size: cover !important;
}

.bg-overlay:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.432);
}



.bg-overlay h1 {
  position: relative;
  z-index: 1;
}

.call-to-action-area {
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.content-text {
  position: relative;
  z-index: 1;
}

.bg-footer {
  background: var(--bg-footer);
}

.social-area {
  list-style: none;
}

.social-area li {
  margin: 5px;
  float: left;
}

.wp-icon {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-shadow: 0 2px 2px #999;
  padding: 0px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: normal;
  line-height: 2em;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
}

.loader {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid var(--heading-color);
  animation: rotate 1.5s linear infinite;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid transparent;
}

.loader::after {
  inset: 0;
  border-bottom: 4px solid var(--main-color);
  animation: rotate 0.5s linear infinite reverse;
}

.loader::before {
  inset: 4px;
  border-left: 4px solid var(--main-color);
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/*
Form Reset
*/

.formContent {
  width: 95% !important;
}

.formContent__checkOpt {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-self: center;
  align-items: center;
}

.form-check-label {
  margin-left: 20px !important;
}

.row_reverse {
  display: flex;
  flex-direction: row-reverse;
}

.row_normal {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .content-text-bg {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse !important;
  }

  .breadcrumb-area {
    padding: 500px 0 100px;
  }
}

@media screen and (max-width: 568px) {
  .breadcrumb-area {
    padding: 350px 0 70px;
  }
}


/* Back To Top*/

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 50px;
  left: 25px;
  z-index: 1;
}

@media screen and (max-width: 568px) {
  .icon-position {
    bottom: 100px;
  }
}

.icon-style {
  background-color: var(--secondary-color);
  border: 2px solid #fff;
  height: 40px;
  width: 40px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}


.absolute-modal {
  z-index: 99999;
}

.mobile-menu {
  left: -200%;
  transition: 0.5s;
}

.mobile-menu.active {
  left: 0;
}

.mobile-menu ul li ul {
  display: none;
}

.mobile-menu ul li:hover ul {
  display: block;
}

.visor_Counter {
  position: fixed;
  bottom: 10px;
  left: 5px;
  border-radius: 50%;
  color: white;
  padding: 16px;
  cursor: pointer;
  z-index: 9;
}


/*Progress Bar*/
#progressBarContainer {
  position: fixed;
  z-index: 20;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 0;
  left: 0;
}


#progressBar {
  background: var(--scroll-color);
  transform-origin: top left;
  transform: scale(0, 0);
}

#progressBarContainer,
#progressBar {
  height: 6px;
}


/*--- Side-Slide --*/

nav.side-slide {
  background-color: var(--main-color);
  top: 0;
  left: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  padding: 50px 20px;
  overflow: scroll;
}

nav.side-slide {
  left: 0;
}

nav.side-slide ul {
  border-top: 1px solid var(--secondary-color);
}

nav.side-slide li {
  text-align: start;
  border-bottom: 1px solid var(--secondary-color);
  line-height: 50px;
  padding: 0 10px;
}

nav.side-slide li:hover {
  background-color: var(--secondary-color);
}

nav.side-slide ul li:hover ul {
  display: block !important;
}

nav.side-slide a {
  color: #ededed;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

nav.side-slide a:hover {
  color: #fff;
}

.bg-navbar-movil {
  background: var(--main-color);
  color: white;
}

/* Estilos header */
.header {
  width: 100%;
  position: absolute;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.wrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-header {
  background: var(--main-color);
}

.bg-about-block {
  background: var(--main-color);
}

@media only screen and (max-width: 700px) {
  .bg-header {
    background: white;
  }
}

/* Clases para colores y Backgrounds */

.color-2 {
  color: var(--secondary-color);
}

.bg-2 {
  background: var(--three-color);
}


.bg-header {
  background: var(--main-color);
}

.bg-about-block {
  background: var(--main-color);
}

.bg-navbar-movil {
  background: var(--main-color);
  color: white;
}

.glass-button {
  padding: 6px 15px;
  border: 0;
  text-decoration: none;
  border-radius: 50px;
  background-color: var(--main-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
}

.glass-button-content {
  display: inline-block;
  padding: 10px 10px;
  border: 0;
  text-decoration: none;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: var(--main-color);
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
}

.glass-button-content:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Hero video  */

.content_video {
  width: 100%;
  height: 110vh;
  position: relative;
  background-color: #000000;
}

.content_video::before {
  content: "";
  position: absolute;
  background: #0000005f;
  width: 100%;
  height: 100%;
  top: 0;
}

@media screen and (max-width: 768px) {
  .content_video {
    height: 120vh;
  }
}


/***************** Appointment *********************/

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 568px) {
  .wrapper {
    justify-content: center;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4ade80;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #4ade80;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4ade80;
  }
}

/***************** Fin Appointment *********************/


/***************** Before & After ***************************/
.before figcaption,
.after figcaption {
  background: #fff;
  border: 1px solid #C0C0C0;
  border-radius: 12px;
  color: #2E3452;
  opacity: 0.8;
  padding: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 100%;
}

.before figcaption {
  left: 12px;
}

.after figcaption {
  right: 12px;
}

/************* fin ******/


/*************** Modal de video *******************/

.modal {
  margin: 15% auto;
  padding: 10px;
  background: #fff;
  border: 1px solid #666;
  width: 60%;
  height: 60vh;
  border-radius: 1rem;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: rgb(255, 0, 0);
  z-index: 999999;
}

.modal .close:hover {
  opacity: 1;
}

.modal .content {
  max-height: 400px;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.overlay .cancel {
  position: absolute;
  right: -30px;
  top: -80px;
  color: white;
  /* width: 100%;
  height: 100%; */
  font-size: 50px;
  cursor: pointer;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width:768px) {
  .modal {
    width: 90%;
    margin: 50% auto;
    height: 40vh;
    padding: 5px;

  }
}

@media screen and (max-width:560px) {
  .overlay .cancel {
    right: 0px;
    top: -80px;
    color: white;
  }
}